.group {
  margin-top: 10px;
  margin-bottom: 4px;
}
.group .header {
  background-color: rgb(50.3875, 71.775, 15.225);
  color: rgb(205.7625, 234.525, 158.475);
  height: 46px;
  line-height: 46px;
  display: flex;
  padding-left: 12px;
  flex: 1 auto;
}
.group .header .text {
  margin-left: 4px;
  color: rgb(237.95625, 247.9125, 221.5875);
}
.group .recent .item {
  background-color: rgb(205.7625, 234.525, 158.475);
  cursor: pointer;
  border-top: 1px solid rgb(195.03125, 230.0625, 137.4375);
  height: 44px;
  line-height: 44px;
  padding-left: 12px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3Mvc2hvcCIsInNvdXJjZXMiOlsiSnVzdEJvdWdodFZpZXcuc2FzcyIsInNob3BWaWV3Q29uZmlnLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBU0E7RUFDRTtFQUNBOztBQUNBO0VBQ0Usa0JBVkc7RUFXSCxPQVRJO0VBVUosUUNaWTtFRGFaLGFDYlk7RURjWjtFQUNBLGNDakJXO0VEa0JYOztBQUNBO0VBQ0U7RUFDQSxPQWhCSTs7QUFrQk47RUFDRSxrQkFwQkU7RUFxQkY7RUFDQTtFQUNBLFFDeEJXO0VEeUJYLGFDekJXO0VEMEJYIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnLi9zaG9wVmlld0NvbmZpZydcblxuJGNvbG9yOiAjOGJjNjJhXG4kZGFyazogZGFya2VuKCRjb2xvciwgMzAlKVxuJGRhcmtlcjogZGFya2VuKCRjb2xvciwgNTAlKVxuJGxpZ2h0OiBsaWdodGVuKCRjb2xvciwgMzAlKVxuJGxpZ2h0ZXI6IGxpZ2h0ZW4oJGNvbG9yLCA0NSUpXG4kc2VwOiBsaWdodGVuKCRjb2xvciwgMjUlKVxuXG4uZ3JvdXBcbiAgbWFyZ2luLXRvcDogMTBweFxuICBtYXJnaW4tYm90dG9tOiA0cHhcbiAgLmhlYWRlclxuICAgIGJhY2tncm91bmQtY29sb3I6ICRkYXJrXG4gICAgY29sb3I6ICRsaWdodFxuICAgIGhlaWdodDogJGhlYWRlci1oZWlnaHRcbiAgICBsaW5lLWhlaWdodDogJGhlYWRlci1oZWlnaHRcbiAgICBkaXNwbGF5OiBmbGV4XG4gICAgcGFkZGluZy1sZWZ0OiAkdGV4dC1wYWRkaW5nXG4gICAgZmxleDogMSBhdXRvXG4gICAgLnRleHRcbiAgICAgIG1hcmdpbi1sZWZ0OiA0cHhcbiAgICAgIGNvbG9yOiAkbGlnaHRlclxuICAucmVjZW50XG4gICAgLml0ZW1cbiAgICAgIGJhY2tncm91bmQtY29sb3I6ICRsaWdodFxuICAgICAgY3Vyc29yOiBwb2ludGVyXG4gICAgICBib3JkZXItdG9wOiAxcHggc29saWQgJHNlcFxuICAgICAgaGVpZ2h0OiAkZWxlbWVudC1oZWlnaHRcbiAgICAgIGxpbmUtaGVpZ2h0OiAkZWxlbWVudC1oZWlnaHRcbiAgICAgIHBhZGRpbmctbGVmdDogMTJweFxuICBcbiIsIiRib3JkZXItcmFkOiA1cHhcbiR0ZXh0LXBhZGRpbmc6IDEycHhcblxuJGhlYWRlci1oZWlnaHQ6IDQ2cHhcbiRlbGVtZW50LWhlaWdodDogNDRweFxuXG5cbiJdfQ== */