.pill {
  height: 48px;
  min-height: 48px;
}

.ingredient {
  background-color: rgb(213.1538461538, 213.1538461538, 160.8461538462);
  white-space: nowrap;
}
.ingredient .icon {
  color: rgb(100.0384615385, 100.0384615385, 44.4615384615);
}
.ingredient.selected {
  background-color: #eeb;
}

.add {
  background-color: #ada;
}
.add .name {
  margin-right: 8px;
}
.add .icon {
  color: #484;
}
.add.selected {
  background-color: #beb;
}

.match {
  color: rgb(228.8461538462, 228.8461538462, 196.1538461538);
  background-color: rgb(138.8769230769, 138.8769230769, 61.7230769231);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvaW5ncmVkaWVudHMiLCJzb3VyY2VzIjpbIkluZ3JlZGllbnRUYWcuc2FzcyIsIi4uLy4uL3VpL3Nob3Auc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLFFDaUJpQjtFRGhCakIsWUNnQmlCOzs7QURmbkI7RUFDRSxrQkNRdUI7RURQdkI7O0FBQ0E7RUFDRTs7QUFDRjtFQUNFOzs7QUFDSjtFQUNFOztBQUNBO0VBQ0U7O0FBQ0Y7RUFDRTs7QUFDRjtFQUNFOzs7QUFDSjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcuLi8uLi91aS9zaG9wJ1xuXG4ucGlsbFxuICBoZWlnaHQ6ICRjbGlja2FibGUtaGVpZ2h0XG4gIG1pbi1oZWlnaHQ6ICRjbGlja2FibGUtaGVpZ2h0XG4uaW5ncmVkaWVudFxuICBiYWNrZ3JvdW5kLWNvbG9yOiAkaW5ncmVkaWVudC1jb2xvci1saWdodFxuICB3aGl0ZS1zcGFjZTogbm93cmFwXG4gIC5pY29uXG4gICAgY29sb3I6ICRpbmdyZWRpZW50LWNvbG9yLWRhcmtcbiAgJi5zZWxlY3RlZFxuICAgIGJhY2tncm91bmQtY29sb3I6ICNlZWJcbi5hZGRcbiAgYmFja2dyb3VuZC1jb2xvcjogI2FkYVxuICAubmFtZVxuICAgIG1hcmdpbi1yaWdodDogOHB4XG4gIC5pY29uXG4gICAgY29sb3I6ICM0ODRcbiAgJi5zZWxlY3RlZFxuICAgIGJhY2tncm91bmQtY29sb3I6ICNiZWJcbi5tYXRjaFxuICBjb2xvcjogbGlnaHRlbigkaW5ncmVkaWVudC1jb2xvci1saWdodCwgMTAlKVxuICBiYWNrZ3JvdW5kLWNvbG9yOiBkYXJrZW4oJGluZ3JlZGllbnQtY29sb3IsIDQlKVxuIiwiJG1lYWwtY29sb3I6ICM4NjZcbiRpbmdyZWRpZW50LWNvbG9yOiAjOTk0XG4kcGx1cy1jb2xvcjogIzY4NlxuJGdyb3VwLWNvbG9yOiAjNjg4XG5cbiRoaWdobGlnaHQtYmFja2dyb3VuZDogIzRjYWNjY1xuJGhpZ2hsaWdodC1jb2xvcjogI2Y4ZmZmZlxuXG4kbWVhbC1jb2xvci1kYXJrOiBkYXJrZW4oJG1lYWwtY29sb3IsIDMwJSlcbiRpbmdyZWRpZW50LWNvbG9yLWRhcms6IGRhcmtlbigkaW5ncmVkaWVudC1jb2xvciwgMTUlKVxuJHBsdXMtY29sb3ItZGFyazogZGFya2VuKCRwbHVzLWNvbG9yLCAxNSUpXG4kZ3JvdXAtY29sb3ItZGFyazogZGFya2VuKCRncm91cC1jb2xvciwgMzAlKVxuXG4kbWVhbC1jb2xvci1saWdodDogbGlnaHRlbigkbWVhbC1jb2xvciwgMzAlKVxuJGluZ3JlZGllbnQtY29sb3ItbGlnaHQ6IGxpZ2h0ZW4oJGluZ3JlZGllbnQtY29sb3IsIDMwJSlcbiRwbHVzLWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRwbHVzLWNvbG9yLCA0MCUpXG4kZ3JvdXAtY29sb3ItbGlnaHQ6IGxpZ2h0ZW4oJGdyb3VwLWNvbG9yLCAzMCUpXG5cbiR0YWctY29sb3ItbWF0Y2g6ICMxMDEwNjBcblxuJGNsaWNrYWJsZS1oZWlnaHQ6IDQ4cHhcblxuPW5hdlxuICBkaXNwbGF5OiBibG9ja1xuICB0ZXh0LWRlY29yYXRpb246IG5vbmVcbiJdfQ== */